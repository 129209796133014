.footer {
  background-color: rgba(0, 82, 73, 0.5);
  // background-color: rgba(0, 0, 0, 0.5);
  margin-top: 10px;

  color: white;
  padding: 10px;
  text-align: center;
}
h4 {
  margin-top: 25px;
  font-size: 10px;
}
.footer-end h4{
  font-size: 10px;
}


.footer-links {
  display: flex;
  justify-content: center;

  margin-bottom: 20px;
}

.footer-links a {
  display: flex;
  justify-content: space-around;
  color: #fffcec;
  text-decoration: none;
  margin: auto;
  cursor: pointer;
}

.footer-links a:hover {
  color: #ddac17;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 10px;
  }
  h4 {
    margin-top: 20px;
    font-size:10px;
  }
  .footer-end h4{
    font-size: 10px;
  }

  .footer-links {
    justify-content: space-around;
    justify-items: baseline;
    align-items: center;
    margin: 3px;
  }

  .footer-links a {
    margin: 10px 0;
  }
  .footer-links a:hover {
    color: #ddac17;
  }
}
