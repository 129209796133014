.formWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    .prettyColumn {
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 700px) {
            height: 18vh;
            padding-top: 10px;

            img {
                display: none;
            }
        }
        .champagneImage {
            width: 150px;
            height: 150px;
        }

        .prettyColumnTitle {
            font-size: 30px;
            font-weight: 600;

            @media (max-width: 700px) {
                font-size: 27px;
            }
        }

        .registerText {
            font-weight: 700;
            font-size: 20px;

            @media (max-width: 700px) {
                font-size: 18px;
            }
        }
    }

    .loginForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 700px) {
            height: 38vh;
        }

        .loginTitle {
            color: white;
            font-size: 25px;

            @media (max-width: 700px) {
                font-size: 18px;
            }
        }

        .inputsContainer {
            display: flex;
            flex-direction: column;
            text-align: start;

            .loginInput {
                background-color: white;
                border:white;
                border-radius: 10px;
                height: 20px;
                width: 260px;
                font-size: 20px;
                padding: 10px;
                margin-bottom: 20px;
            }

            .loginButton {
                background-color: #be962e;
                border: #be962e;
                border-radius: 10px;
                height: 45px;
                width: 280px;
                font-size: 20px;
                padding: 20px;
                color: black;
                align-items: center;
                justify-content: center;
                display: flex;
                font-family: sans-serif;
            }
        }
    }
}