
.body8{
    background-image: url("../../../images/n60.png");
   
      max-width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; /* Evita que la imagen se repita */
      height: 190vh;
      font-family: 'Libre Baskerville', serif;
      text-align: center;
     
  }

  
  .info-container9 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    padding: 20px;
  }
  
  .info-content9 {
    display: flex;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 90px;
    padding: 10px;
    font-family: 'Libre Baskerville', serif;
  }
  
  .info-content9 .info-text9 {
    flex: 1;
    padding: 0 20px;
  }
  
  .info-content9 h1 {
    font-size: 30px;
    margin-top: 50px;
    margin-bottom: 10px;
    color:#7E466A;
    font-family: 'Libre Baskerville', serif;
    text-align: center;
  }
  
  .video{
    padding: 30px;
    margin-top: 30px;
  
  }
  .info-image2 {
    padding-top: 40px;
    flex: 1;
    text-align: center;
  }
  
  .info-image2 img {
    max-width: 100%;
    height: auto;
  }
  
 
  .info-content9 p {
    font-size: 20px;
    line-height: 1.5;
    color:#7A624D;
  }
  
  
  .four-column-container8 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px; /* Espacio entre las columnas */
  }
  
  .column8 {
    padding: 20px;
    text-align: justify;
    color:#596E75;
  }
  
  .column8 img {
    max-width: 100%;
 height: auto;
    display: block;
    margin: 0 auto 10px;
  }
  
  .column8 h2 {
    font-size: 1.5rem;
    margin: 10px 0;
    text-align: center;
  }
  
  h2{
    font-size: 35px;
  }
  .column8 p {
    font-size: 1rem;
    color:#7A624D;
    text-align: center;
  }
  .image6 img {

    max-width: 600px;
    height: auto;
  }
  
  .color{
    color:#7A624D;
  
  }
  
  .color2{
    color:#FF8695;
  }
  .color3{
  color:#006259;
  }
  .color4{
    color:#00C9BE;
    
  }
  .color5{
  color:#FF8695;
  }
  .color6{
color: #2D6576;
  }

  @media (max-width: 768px) {

    .info-content9 {
      flex-direction: column;
      text-align: left;
      margin-top: 50px;
    }
  
    .info-content9 .info-text9 {
      padding: 5px;
    }
  
    .info-content9 h1 {
      font-size: 24px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  
    .info-image2 {
      padding-top: 20px;
    }
  
    
    .four-column-container8 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .columnn8 {
      padding: 10px;
    }
  
    .column8 h2 {
      font-size: 1.2rem;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  
    .column8 p {
      font-size: 0.9rem;
    }
  
    .image4 img{
  width: 300px;
  height: auto;
    }
  }
  