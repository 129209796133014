
.zoomable-image {
    cursor: pointer;
    transition: transform 0.3s;
 
  }
  
  .zoomable-image:hover {
    transform: scale(1.5); 
  }
  