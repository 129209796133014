.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: opacity(1) brightness(0.6);

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
