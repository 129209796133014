body{
 
background-color:#D5CABD;
  max-width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  height: 130vh;
  text-align: justify;
  font-family: 'Libre Baskerville', serif;
}

.martha-page{
background-image: url("../../../images/n30.png");
max-width: 100%;
background-size: cover;
background-position: center;
background-repeat: no-repeat; /* Evita que la imagen se repita */
height: 150vh;
font-family: 'Libre Baskerville', serif;

}


.info-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: justify;

}

.info-content2 {
  display: flex;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 90px;
  padding: 10px;
  font-family: 'Libre Baskerville', serif;
  text-align: justify;
}

.info-content2 .info-text2 {
  flex: 1;
  padding: 0 20px;
}

.info-content2 h1 {
  font-size: 30px;
  margin-top: 50px;
  margin-bottom: 10px;
  color:#B14976;
  font-family: 'Libre Baskerville', serif;
  text-align: center;
}

.video{
  padding: 30px;
  margin-top: 30px;

}
.info-image2 {
  padding-top: 40px;
  flex: 1;
  text-align: center;
}

.info-image2 img {
  max-width: 100%;
  height: auto;
}


.info-content2 p {
  font-size: 20px;
  line-height: 1.5;
  color:#7A624D;
}



.four-column-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px; /* Espacio entre las columnas */
}

.column {
  padding: 20px;
  text-align: justify;
  color:#B14976;
}

.column img {
  max-width: 100%;
 height: auto;
  display: block;
  margin: 0 auto 10px;
}

.column h2 {
  font-size: 1.5rem;
  margin: 10px 0;
  text-align: center;
}

h2{
  font-size: 30px;
}
.column p {
  font-size: 1rem;
  color: #458A7C;
  text-align: center;
}

.color p{
  
  color:#7A624D;
  text-align: left;
  
}

.color2{
  color:#B14976;
}
.color3{
color:#B14976;
}
.color4{
  color:#7A624D;
  
}
.color5{
color:#B14976;
}


@media (max-width: 768px) {
  .info-content2 {
    flex-direction: column;
    text-align: left;
    margin-top: 50px;

  }

  .info-content2 .info-text2 {
    padding: 5px;
    text-align: left;
  }
  .info-content2 .color {
    text-align: left;
  }
.color p{
  text-align: left
}
  .info-content2 h1 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .info-image2 {
    padding-top: 20px;
  }

  
  .four-column-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .column {
    padding: 10px;
  }

  .column h2 {
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .column p {
    font-size: 0.9rem;
  }

  .image3 img{
width: 300px;
height: auto;
  }
  
}
