.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  margin: 0;
}

.bounce-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ddac17;
  color: white;
  font-family: 'Libre Baskerville', serif;
  text-decoration: none;
  font-weight: bold;
  margin-top: 25px;
  border-radius: 5px;
  animation: bounce 5s infinite;
  cursor: pointer;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
@media (max-width: 767px) {
  .container {
    height: 20vh; /* Ajustar altura en móviles */
  }

  .bounce-button {
    padding: 8px 16px; /* Ajustar padding en móviles */
    font-size: 14px; /* Ajustar tamaño de fuente en móviles */
  }
}

/* Media query para tablets y computadoras */
@media (min-width: 768px) {
  .container {
    height: 15vh; /* Ajustar altura en tablets y computadoras */
  }

  .bounce-button {
    padding: 10px 20px; /* Ajustar padding en tablets y computadoras */
    font-size: 16px; /* Ajustar tamaño de fuente en tablets y computadoras */
  }
}
