.comment-form {
  width: 70%;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ddac17;
  // background-color: #005249;

  background-color: rgba(18, 79, 72, 0.7);
  border-radius: 9px;
  font-family: 'Libre Baskerville', serif;
}
.comment-form h2 {
  margin-bottom: 16px;
  color:#ddac17;
  font-size: 50px;
}
.comment-form form {
  display: flex;
  flex-direction: column;
  
}

.subtexto, .subreaction{
  font-size: 12px;
  color:#7A624D;

  flex-direction: column;

}



.comment-form label {
  font-weight: bold;
  margin-top: 10px;
  color: #ddac17;
  padding: 10px;
 
}
.comment-form textarea,
.comment-form select {
  margin-top: 5px;
  padding: 10px;
  border: 1px solid#ddac17;
  border-radius: 6px;
  background-color: floralwhite;
}

.comment-form button {
  margin-top: 20px;
  align-self: center;
  padding: 10px 10px;
  background-color: #005249;
  color: floralwhite;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.comment-form button:hover {
  background-color: #4b8178;
}
.comments-list {
  margin-top: 20px;
}
.comments-list h3 {
  margin-bottom: 10px;
  color: #ddac17;
}
.comments-list ul {
  list-style: none;
  padding: 0;
}

.comments-list li {
  margin-bottom: 15px;
  margin: 5px;
  padding: 10px;
  background-color:floralwhite;
  border: 1px solid #ddac17;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comments-list li button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 2px 5px;
  border-radius: 3px;
  cursor: pointer;
}
.comments-list li button:hover {
  background-color: #c82333;
}

@media screen and (max-width: 768px) {
  .comment-form {
    width: 90%; /* Ajusta el ancho del formulario en dispositivos pequeños */
    font-size: 16px; /* Ajusta el tamaño de fuente en dispositivos pequeños */
  }

  .comment-form h2 {
    font-size: 40px; /* Ajusta el tamaño de fuente del título en dispositivos pequeños */
  }

  /* Estilos para los comentarios en dispositivos móviles */
  .comments-list li {
    flex-direction: column; /* Apila los elementos de comentario en dispositivos móviles */
  }
  .p{
    font-size: 12px;
    color: black;
   

  
  }
  
  .comments-list li button {
    margin-top: 20px; /* Espaciado adicional entre el comentario y el botón en dispositivos móviles */
  }

}

