.button-container {
  display: flex;
  justify-content: left;
}

button.big-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.5rem;

  background: linear-gradient(to right #005249);
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.big-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
 
}
