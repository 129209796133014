.App {
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* background-color:#B4AA99; */

  background-size: cover;
  background-repeat: no-repeat;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .App {
    /* Estilos para dispositivos móviles */
    font-size: 14px;
    background-size: contain;
    padding: 20px;
    background-repeat: no-repeat;
    /* Asegurarse de que no se repita en móviles */
  }
}

/* Media query para computadoras */
@media (min-width: 768px) {
  .App {
    /* Estilos para computadoras */
    font-size: 16px;
    padding: 40px;
  }
}