.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #005249;
  color: #ddac17;
  padding: 10px 20px;

  .auth-button {
    border: 1px solid white;
    padding: 5px;
    margin-top: -10px;
    font-family: Arial, sans-serif;
    border-radius: 10px;
    font-size: 15px;
  }


  @media screen and (max-width: 768px) {
    position: relative;
    height: 100px;
  }
}

.logo img {
  width: 250px;
  height: 175px;
}
.menu-toggle {
  margin-right: 25px;
}
.menu-toggle {
  display: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.menu-toggle.open .bar {
  background-color: #ddac17;
}

.bar {
  width: 30px;
  height: 6px;
  background-color: #ddac17;
  margin: 3px 0;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}
.icon-login2 {
  margin-left: 10px;
  
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 768px) {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 200px;
    padding: 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.nav-links.active {
  display: flex;
}

.nav-links li {
  margin-right: 40px;
}

.nav-links a {
  text-decoration: none;
  color: #fffcec;
  font-weight: bold;
}

.nav-links a:hover {
  color: #ddac17;
}
