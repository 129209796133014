.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 10px;
  margin-top: 100px;
  margin-bottom: 240px;
}

.info-content {
  display: flex;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 90px;
  padding: 10px;
  font-family: 'Libre Baskerville', serif;

  .info-text {
    flex: 1;
    padding: 0 20px;
  }

  h1 {
    font-size: 30px;
    margin-top: 50px;
    margin-bottom: 10px;
    color: #006e61;
    font-family: 'Libre Baskerville', serif;
  }
}

p {
  font-size: 20px;
  line-height: 1.5;
  color:#fffcec;
}
button {
  margin-top: 10px;
  align-self: center;
  padding: 5px 10px;
  background-color: #005249;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-family: 'Libre Baskerville', serif;
}
button:hover {
  background-color: #4b8178;
}
.text-color {
  font-size: 26px;

  color: #ddac17;
}

.info-image {
  padding-top: 40px;
  flex: 1;
  text-align: center;
}

.info-image img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .info-container {
    height: auto;
    padding: 20px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: justify;
  }

  .info-content {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin-top: 0;
    padding: 20px;
  }

  .info-text {
    padding: 20px 0;
    text-align: center;
  }

  h1 {
    font-size: 19px;
    margin-top: 30px;
  }

  .info-image {
    margin-top: 40px;
  }

  .info-image img {
    max-width: 90%;
    height: auto;
  }
}
