body {
  margin: 0;
  padding: 0;
}
.brindis-page {
  background-image: url("/images/brindisf4.jpeg");
  max-width: 100%;
  background-size: cover;
  background-position: center;

 
}

.header {

  color: antiquewhite;
  text-align: center;
  padding: 20px;
  font-family: 'Libre Baskerville', serif;
  font-size: 30px;
}

.main {
  display: flex;
  justify-content: center;
  padding: 20px;
  font-family: 'Satisfy', cursive;
}


.column {
  font-family: 'Satisfy', cursive;
  margin: 0 10px;

}

ul {
  list-style: none;
  padding: 0;
}

li {

  margin-bottom: 15px;
  
 
}
.icon-login3 {
  margin-right: 10px;
  color: #ddac17;
}

a {
  text-decoration: none;
  color: antiquewhite;
  transition: color 0.3s;
  font-size: 18px;
}

a:hover {
  color: #3a988d;
}


@media (max-width: 768px) {
  .main {
    flex-direction: column; 
    align-items: center; 
  }

  .column {
    margin: 10px 0; 
  
  }

  h1 {
    font-size: 60px;
    margin-bottom: 0%; 
    font-family: 'Satisfy', cursive;
    color: antiquewhite;
  }

  a {
    font-size: 22px;
     
  }
}
