.about-container {
  background-image: url("/images/aboutf2.jpeg");

  background-size: cover;
  background-position: center;
}

.about-title {
  text-align: center;
  margin: 20px;
  margin-left: 20px;
  margin-right: 20px;
  color: antiquewhite;
  padding: 20px;
  font-family: cursive;
  font-size: 25px;
}
.about-text {
  text-align: justify;
  margin: 30px;
}
.about-image {
  text-align: center;
  margin-top: 20px;
}
.about-image img {
  width: 270px;
  height: 350px;
}
